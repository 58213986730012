<template>
  <div class="info-box-item" v-if="leave.type ==1">
    <div class="item-head">
      <img class="head-img" :class="{ active: leave.style == 0 }" :src="leave.avatar? leave.avatar: 'https://img.chinamallsales.com/markeTavatar.png?1'" />
      <img class="head-circle" v-if="leave.style > 0" :src="leave.style? info[leave.style].circle: 'https://img.chinamallsales.com/webh5/market_head_circle.png'" />
    </div>
    <div class="item-leave">
      <div class="leave-name"> {{leave.nickname}}</div>
      <div class="leave-msg">{{leave.content}}</div>
    </div>
    <div class="item-trailer">
      <img v-if="leave.style > 0" :src="leave.style? info[leave.style].trailer: 'https://img.chinamallsales.com/webh5/market_trailer.png'" />
    </div>
  </div>
</template>

<script>
  export default {
    name: "leaveItem",
    props: ['leave'],
    data() {
      return {
        info: [
          {
            circle: ' ',
            trailer: ' '
          },
          {
            circle: 'https://img.chinamallsales.com/screenChat/skinHeader001.png',
            trailer: 'https://img.chinamallsales.com/screenChat/skinContent001.png'
          },
          {
            circle: 'https://img.chinamallsales.com/screenChat/skinHeader002.png',
            trailer: 'https://img.chinamallsales.com/screenChat/skinContent002.png'
          },
          {
            circle: 'https://img.chinamallsales.com/screenChat/skinHeader003.png',
            trailer: 'https://img.chinamallsales.com/screenChat/skinContent003.png'
          },
          {
            circle: 'https://img.chinamallsales.com/screenChat/skinHeader004.png',
            trailer: 'https://img.chinamallsales.com/screenChat/skinContent004.png'
          },
          {
            circle: 'https://img.chinamallsales.com/screenChat/skinHeader005.png',
            trailer: 'https://img.chinamallsales.com/screenChat/skinContent005.png'
          },
        ]
      }
    },
  }
</script>

<style lang="less" scoped>
    .info-box-item {
      width: 97%;
      height: 6rem;
      display: flex;
      border-radius: 3rem;
      position: relative;
      background-color: rgba(255, 255, 255, 0.2);
      border: 0.25rem solid rgba(255, 255, 255, 0.2);
      .item-head {
        position: relative;
        width: 7rem;
        margin-top: -0.1875rem;
        margin-left: -0.9375rem;
        .head-img {
          position: absolute;
          width: 4.1rem;
          height: 4.1rem;
          border-radius: 100%;
          top: 1rem;
          left: 1.4rem;
        }
        .active{
           border: 2px solid #FFFFFF;
        }
        .head-circle {
          position: absolute;
          width: 6.625rem;
          height: 6.625rem;
        }
      }
      .item-leave {
        width: 63rem;
        padding: 1.125rem 0;
        color: #fff;
        .leave-name {
          font-size: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .leave-msg {
          font-size: 1.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: .25rem;
        }
      }
      .item-trailer {
        position: absolute;
        width: 3.9375rem;
        height: 3.9375rem;
        bottom: -0.25rem;
        right: -1.25rem;
        > img {
          width: 3.9375rem;
          height: 3.9375rem;
        }
      }
    }
</style>