<template>
  <div class="container" ref="containerHeight">
    <div class="market-top" v-if="topItem">
        <div class="market-top-item">
          <leave-item :leave="topItem"></leave-item>
        </div>
    </div>
    <div class="market-box" ref="markeBox">
      <swiper :topItem="topItem"></swiper>
    </div>
    <div class="weChat" v-if="detail.ewm==1">
      <img :src="detail.ewm_url"/>
      <text>扫码发表留言</text>
    </div>
  </div>
</template>

<script>
import 'amfe-flexible'  //移动端rem适配
import '../../utils/rem'
import leaveItem from "@/components/Message/leaveItem.vue";
import swiper from "@/components/Swiper/swiper.vue";
import { getDapingShowTop,getDapingDetail,getDapingCloseTop } from "@/api/remarkMessage";

export default {
    components: {
        swiper,
        leaveItem
    },
    data() {
        return {
          actId: null,
          topItem: null,
          timer: null,
          detail: {},
        }
    },
    watch: {
        screenWidth(val) {
          if (!this.screenTimer) {
              this.screenWidth = val;
              this.screenTimer = true;
              let that = this;
              setTimeout(function () {
              // 打印screenWidth变化的值
              //console.log(that.screenWidth)
              that.screenTimer = false;
              }, 400);
          }
        }
    },
    created(){
       this.actId = this.$route.query.id || 2
    },
    mounted() {
        const _this = this;
        this.dapingDetail()
        _this.resizeCount();
        this.timer = setInterval(this.dapingShowTop, 10000)

    },
    methods: {
        //页面拖动自适应
        resizeCount() {
            const baseSize = 16;
            const scale = document.documentElement.clientWidth / 1200;
            document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + "px";
            window.screenWidth = document.body.clientWidth;
            window.screenHeight = document.body.clientHeight;
            this.clientWidth = window.screenWidth;
            this.clientHeight = window.screenHeight;
        },
        dapingDetail(){
          getDapingDetail({ act_id: this.actId }).then(res=>{
            if (res.status == 200) {
                const info = res.data.info;
                this.detail = info;
            }
          })
        },
        //每10秒获取一条置顶留言
        dapingShowTop() {
          getDapingShowTop({ act_id: this.actId }).then(res => {
              if (res.status == 200) {
                const info = res.data.info
                info.content = decodeURIComponent(info.content)
                this.topItem = info
                setTimeout(this.dapingCloseTop, 10000)
              }else{
                this.topItem = null
              }
          })
        },
        //取消置顶
        dapingCloseTop(){
          getDapingCloseTop({act_id: this.actId, id: this.topItem.id}).then(res => {
              if (res.status == 200) {
                console.log(res)
              }
          })
        }
    },
    onBeforeUnmount(){
      clearInterval(this.timer)
    }
};
</script>

<style lang="less" scoped>

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: linear-gradient(180deg, #2872fd 0%, #387cf1 65%, #90b9ff 100%);
  padding: 0 1.2rem 1.875rem 1.2rem;
  box-sizing: border-box;
  position: relative;
  .market-top{
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 0;
    background: #2872fd;
    padding: 0 1.2rem 0 1.2rem;
    z-index: 99;
    width: calc(100% - 20px);
    .market-top-item{
      padding: 0 1.2rem 0 1.2rem;
    }
  }
  .market-box {
    width: 100%;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box; 
  }
  .weChat {
    position: fixed;
    bottom: 1.875rem;
    right: 1.25rem;
    width: 9.6875rem;
    height: 11.9375rem;
    background-color: #fff;
    border-radius: 0.9375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    > text {
      color: #333;
      font-size: 1rem;
      margin-top: 0.625rem;
    }
    > img {
      width: 8.3125rem;
      height: 8.3125rem;
    }
  }
}
</style>